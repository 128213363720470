<!-- eslint-disable no-restricted-syntax -->
<template>
  <section
    v-if="Object.values(getAllPackagesAvailable)"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center mt-5">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
    </div>
    <b-tabs
      class="tabClass"
      align="center"
    >
      <b-tab
        active
        title="Monthly"
        class="media-card"
      >
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row>
              <b-row class="d-flex justify-content-center">
                <span
                  v-for="(fullPackage, index ) in Object.values(getAllPackagesAvailable)"
                  :key="index"
                  :class="loader ? 'mr-3 mb-3': null"
                >
                  <span
                    v-if="fullPackage.id === 1 || fullPackage.id === 2 || fullPackage.id === 3 || fullPackage.id === 4 || fullPackage.id === 5"
                  >
                    <b-skeleton-img
                      v-if="loader"
                      no-aspect
                    />
                    <b-card
                      v-else
                      class="m-1"
                    >
                      <div class="d-flex flex-column align-items-center justify-content-center">
                        <div
                          v-show="fullPackage.trial_month_count>0"
                          class="pricing-badge text-right"
                        >
                          <b-badge
                            variant="danger"
                          >
                            {{ fullPackage.trial_month_count=== 1 ? `Free ${fullPackage.trial_month_count} month trial` : `Free ${fullPackage.trial_month_count} months trial` }}
                          </b-badge>
                        </div>
                        <!-- img -->
                        <div>
                          <b-img
                            src="@/assets/images/simiicons/device.svg"
                            class="mb-1 mt-1 d-block"
                            alt="basic svg img"
                          />
                        </div>
                        <!--/ img -->
                        <h3 class="mb-0">{{ fullPackage.name }}</h3>
                      </div>
                      <!-- annual plan -->
                      <div
                        class="d-flex align-items-center justify-content-center annual-plan"
                      >
                        <div
                          class="plan-price mt-2"
                        >
                          <sup class="font-medium-1 font-weight-bold text-primary">AUD</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{ packagePrice(fullPackage) }}</span>
                          <sub
                            class="pricing-duration text-body font-medium-1 font-weight-bold"
                          > / Screen</sub>
                        </div>
                      </div>
                      <b-list-group
                        :class="`list-group-circle text-left pb-2 ${fullPackage.id === 1 || fullPackage.id === 6 ? 'mb-4' : 'mb-1'}`"
                        :style="`${fullPackage.slug ==='power-saver'? 'min-height: 475px' : 'none'}`"
                      >
                        <b-list-group-item
                          v-for="(data,i) in fullPackage.id === 1 || fullPackage.id === 6 ? planFeatureListEnum.starterPlanfeaturesList : fullPackage.id === 2 || fullPackage.id === 7 ? planFeatureListEnum.multiPlanfeaturesList : fullPackage.id === 3 | fullPackage.id === 8 ? planFeatureListEnum.networkPlanfeaturesList : fullPackage.id === 5 ? planFeatureListEnum.advertiserPlanfeaturesList : planFeatureListEnum.featuresListPowerSaving"
                          :key="i"
                        >
                          {{ data }}
                        </b-list-group-item>
                      </b-list-group>

                      <!--sub members-->
                      <div
                        class="position-bottom-0 position-relative btns-class pl-2"
                      >

                        <div v-if="fullPackage.slug==='3-plus-screens' || fullPackage.slug==='network-plan-yearly'">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in screens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <div v-if="fullPackage.slug==='2-screens' || fullPackage.slug==='multi-plan-yearly'">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in multiScreens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <strong class="d-flex align-items-center justify-content-center">{{ group.quota }}</strong>
                        <!-- /sub members-->
                        <!-- buttons -->
                        <div>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            block
                            class="mt-2"
                            variant="primary"
                            :disabled="spinner"
                            @click="selectPackageFunc(fullPackage.id, index)"
                          >
                            <div
                              v-if="spinner"
                              class="spinner"
                            >
                              <b-spinner
                                small
                              />
                              <span class="sr-only">Loading...</span>
                            </div>
                            Select
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </span>
                </span>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Yearly"
        class="media-card"
      >
        <b-row class="pricing-card">
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row>
              <b-row class="d-flex justify-content-center">
                <span
                  v-for="(fullPackage, index ) in Object.values(getAllPackagesAvailable)"
                  :key="index"
                  :class="loader ? 'mr-3 mb-3': null"
                >
                  <span
                    v-if="fullPackage.id === 6 || fullPackage.id === 7 || fullPackage.id === 8 || fullPackage.id === 4 || fullPackage.id === 5"
                  >
                    <b-skeleton-img
                      v-if="loader"
                      no-aspect
                    />
                    <b-card
                      v-else
                      class="m-1"
                    >
                      <div class="d-flex flex-column align-items-center justify-content-center">
                        <div
                          v-show="fullPackage.trial_month_count>0"
                          class="pricing-badge text-right"
                        >
                          <b-badge
                            variant="danger"
                          >
                            {{ fullPackage.trial_month_count=== 1 ? `Free ${fullPackage.trial_month_count} month trial` : `Free ${fullPackage.trial_month_count} months trial` }}
                          </b-badge>
                        </div>
                        <!-- img -->
                        <div>
                          <b-img
                            src="@/assets/images/simiicons/device.svg"
                            class="mb-1 mt-1 d-block"
                            alt="basic svg img"
                          />
                        </div>
                        <!--/ img -->
                        <h3 class="mb-0">{{ fullPackage.name }}</h3>
                      </div>
                      <!-- annual plan -->
                      <div
                        class="d-flex align-items-center justify-content-center annual-plan"
                      >
                        <div
                          class="plan-price mt-2"
                        >
                          <sup class="font-medium-1 font-weight-bold text-primary">AUD</sup>
                          <span class="pricing-basic-value font-weight-bolder text-primary">{{ packagePrice(fullPackage) }}</span>
                          <sub
                            class="pricing-duration text-body font-medium-1 font-weight-bold"
                          > / Screen</sub>
                        </div>
                      </div>
                      <!--/ annual plan -->
                      <!-- plan benefit -->
                      <!-- <div
                v-for="(feature, fIndex) in fullPackage.plan_has_feature"
                :key="fIndex"
                class=""
              >
                <b-form-checkbox
                  v-if="!feature.feature.isDisabled"
                  v-model="feature.feature.isSelected"
                  size="sm"
                  plain
                  :name="feature.feature.name"
                  :value="true"
                  :disabled="feature.feature.isDisabled"
                  @change="handleFeatureList(index)"
                >{{ feature.feature.name }}</b-form-checkbox>
              </div> -->
                      <b-list-group
                        :class="`list-group-circle text-left pb-2 ${fullPackage.id === 1 || fullPackage.id === 6 ? 'mb-4' : 'mb-1'}`"
                        :style="`${fullPackage.slug ==='power-saver'? 'min-height: 475px' : 'none'}`"
                      >
                        <b-list-group-item
                          v-for="(data,i) in fullPackage.id === 1 || fullPackage.id === 6 ? planFeatureListEnum.starterPlanfeaturesList : fullPackage.id === 2 || fullPackage.id === 7 ? planFeatureListEnum.multiPlanfeaturesList : fullPackage.id === 3 | fullPackage.id === 8 ? planFeatureListEnum.networkPlanfeaturesList : fullPackage.id === 5 ? planFeatureListEnum.advertiserPlanfeaturesList : planFeatureListEnum.featuresListPowerSaving"
                          :key="i"
                        >
                          {{ data }}
                        </b-list-group-item>
                      </b-list-group>

                      <!--sub members-->
                      <div
                        class="position-bottom-0 position-relative btns-class pl-2"
                      >

                        <div v-if="fullPackage.slug==='3-plus-screens' || fullPackage.slug==='network-plan-yearly'">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in screens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <div v-if="fullPackage.slug==='2-screens' || fullPackage.slug==='multi-plan-yearly'">
                          <b-dropdown
                            v-model="fullPackage.screenQuota "
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            :text="`No of screens ${fullPackage.screenQuota}`"
                            variant="outline-secondary"
                            menu-class="w-100"
                            right
                            block
                          >
                            <span
                              v-for="option in multiScreens"
                              :key="option.key"
                            >
                              <b-dropdown-divider />
                              <b-dropdown-item @click="setValueOfScreens(index, option)">
                                {{ option.key }}
                              </b-dropdown-item>
                            </span>
                          </b-dropdown>
                        </div>
                        <strong class="d-flex align-items-center justify-content-center">{{ group.quota }}</strong>
                        <!-- /sub members-->
                        <!-- buttons -->
                        <div>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            block
                            class="mt-2"
                            variant="primary"
                            :disabled="spinner"
                            @click="selectPackageFunc(fullPackage.id, index)"
                          >
                            <div
                              v-if="spinner"
                              class="spinner"
                            >
                              <b-spinner
                                small
                              />
                              <span class="sr-only">Loading...</span>
                            </div>
                            Select
                          </b-button>
                        </div>
                      </div>
                    </b-card>
                  </span>
                </span>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <create-group-modal
        ref="create-group-modal"
        :edit-group-modal="editGroupModal"
      />
    </b-tabs>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BListGroup, BListGroupItem, BBadge, BButton, BDropdown, BDropdownItem, BDropdownDivider, BSpinner, BSkeletonImg,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SiteType from '@/common/enums/siteTypeEnum'
import screens from '@/common/enums/noOfScreenEnum'
/* eslint-disable global-require */
import ApplicationType from '@/common/enums/applicationTypeEnum'
import CreateGroupModal from '@/views/groups/CreateGroupModal.vue'
import { showToast, packagePrice } from '@/common/global/functions'
import PackageFeatures from '@/common/enums/packagesFeatureTypeEnum'
import planFeatureListEnum from '@/common/enums/planFeatureListEnum'

export default {
  components: {
    CreateGroupModal,
    BButton,
    // BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
    BSkeletonImg,
    BImg,
    BBadge,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  async beforeRouteEnter(to, from, next) {
    // const userOwnGroup = await store.getters['user/getUserOwnCreatedGroup']
    const user = await store.getters['user/getUser']
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (isUserAdmin) {
      next({
        path: '/',
      })
      return
    }
    if (!isUserAdmin && !user.is_phone_number_verified) {
      next({
        name: 'phone-verification',
      })
      return
    }
    if (!isUserAdmin && !user.is_profile_completed) {
      next({
        name: 'complete-profile',
      })
      return
    }
    next()
  },

  data() {
    return {
      SiteType,
      packagePrice,
      screens,
      loader: false,
      spinner: false,
      selectedScreen: [],
      status: 'monthly',
      monthlyPlanShow: true,
      memberValue: [],
      totalPrice: null,
      planFeatureListEnum,
      multiScreens: [
        { key: 2, value: 'No of Screens 2' },
        { key: 3, value: 'No of Screens 3' },
        { key: 4, value: 'No of Screens 4' },
        { key: 5, value: 'No of Screens 5' },
      ],
      group: {
        planId: null,
        quota: null,
        price: null,
        packageName: '',
        features: [],
        description: null,
      },
      editGroupModal: false,
    }
  },
  computed: {
    getAllPackagesAvailable() {
      return this.$store.getters['user/getAllPackagesAvailable']
    },

  },
  async mounted() {
    this.loader = true
    await this.$store.dispatch('user/setPackagesAvailable')
    this.loader = false
  },
  methods: {
    openConfirmationModal() {
      this.$refs['create-group-modal'].show()
    },
    handleFeatureList(index) {
      packagePrice(this.getAllPackagesAvailable[index])
    },
    setValueOfScreens(index, screenValue) {
      const pack = this.getAllPackagesAvailable[index]
      pack.screenQuota = screenValue.key
      packagePrice(pack)
    },
    selectPackageFunc(id, index) {
      const selectedScreen = this.getAllPackagesAvailable[index]
      let isChecked = false
      selectedScreen.plan_has_feature.forEach(planHasFeature => {
        if (planHasFeature.feature.slug !== PackageFeatures.DEVICES) {
          if (planHasFeature.feature.isSelected) {
            isChecked = true
          }
        }
      })
      if (isChecked) {
        this.spinner = true
        this.openConfirmationModal()
        this.$store.commit('user/SET_CREATE_GROUP_DATA', { ...selectedScreen })
        this.spinner = false
      } else {
        showToast('Pricing Plans', 'Please select atleast one feature', 'danger', 4000)
      }
    },
    filterFeatureName(name, quantity) {
      if (name === 'Site' && quantity === SiteType.SITE) {
        return 'Site'
      }
      if (name === 'Site' && quantity === SiteType.MULTI_SITE) {
        return 'Multi Site'
      }
      return name
    },
    hasLauncherFeature(features) {
      let found = false
      found = features.some(el => el.feature.application_type === ApplicationType.SIMI_LAUNCHER)
      return found
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
#pricing-plan{
  .dropdown-item {
    padding: 0.05rem 1.28rem !important;
  }
  .card-body{
    margin: 1rem !important;
    padding: 1rem !important;
    position: relative;
    margin-top: 0px !important;
  }
  .card{
    min-height:550px !important;
    min-width: 300px !important;
  }
  .b-skeleton-img{
    min-height:450px !important;
    min-width: 300px !important;
  }
  .row{
    margin: 0px !important;
  }

  .btns-class{
    width: 100%;
    margin: -15px;
    margin-bottom: 15px;
  }
   .list-group-item{
     padding: 0.5rem .25rem !important;
     padding-left: 1.5rem
   }
 .list-group.list-group-circle .list-group-item{
    padding-left: 1.5rem !important;
  }
}
</style>

const planFeatureListEnum = {
  starterPlanfeaturesList: [
    '1 Venue',
    '1 Screen',
    'Ad Revenue enabled',
    'Unlimited Users',
    'Unlimited Profiles',
    'Content Creator',
    'Content Schedular',
    'Media Cloud Storage 5GB',
    'Internal Ads Manager',
    'External Ads Manager',
    'Ad Agency Partner',
    'Reports Dashboard',
    'Venues Dashboard',
    'Screens Dashboard',
    'Proof of Play',
    'Basic Support (ticket)',
  ],
  multiPlanfeaturesList: [
    'Up to 5 Venues',
    'Up to 5 Screens',
    'Ad Revenue enabled',
    'Unlimited Users',
    'Unlimited Profiles',
    'Content Creator',
    'Content Schedular',
    'Media Cloud Storage 5GB',
    'Internal Ads Manager',
    'External Ads Manager',
    'Ad Agency Partner',
    'Reports Dashboard',
    'Venues Dashboard',
    'Screens Dashboard',
    'Proof of Play',
    'Priority Support',
  ],
  networkPlanfeaturesList: [
    'Unlimited Venues',
    'Unlimited Screens',
    'Ad Revenue enabled',
    'Unlimited Users',
    'Unlimited Profiles',
    'Content Creator',
    'Content Schedular',
    'Media Cloud Storage 5GB',
    'Internal Ads Manager',
    'External Ads Manager',
    'Ad Agency Partner',
    'Reports Dashboard',
    'Venues Dashboard',
    'Screens Dashboard',
    'Proof of Play',
    'Dedicated Support',
  ],
  advertiserPlanfeaturesList: [
    'Unlimited Users',
    'Unlimited Brands',
    'Content Creator',
    'Content Schedular',
    'Network Ads Manager',
    'Venue Ads Dashboard',
    'Screens Ads Dashboard',
    'Payment Dashboard',
    'Billing Dashboard',
    'Premium Ad Placements',
    'Premium Ad Rates',
    'Proof of Play',
    'Priority Support',
  ],
  featuresListPowerSaving: [
    'Accounts',
    'Invoices',
    'Balances',
    'Usage',
  ],
}
export default planFeatureListEnum
